import Head from "next/head";
import { type HomeDocument, type PageDocument } from "prismicio-types";

export const SeoMeta = (page: PageDocument | HomeDocument) => {
  const {
    meta_title,
    meta_description,
    social_title,
    social_description,
    social_image,
    twitter_card,
    twitter_site,
  } = page.data;

  const domainName =
    process.env.NODE_ENV === "development"
      ? process.env.NEXT_PUBLIC_LOCALHOST_NAME
      : process.env.NEXT_PUBLIC_DOMAIN_NAME;

  const pageUrl = page.url ?? "";
  const fullUrl = `${domainName ?? ""}${pageUrl ?? ""}`;
  const canonicalUrl = page.type === "home" ? `${domainName ?? ""}/` : fullUrl;

  return (
    <Head>
      <title>{meta_title}</title>
      <meta name="description" content={meta_description ?? ""} />

      <meta property="og:url" content={fullUrl} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={social_title ?? ""} />
      <meta property="og:description" content={social_description ?? ""} />
      <meta property="og:image" content={social_image.url ?? ""} />

      {/* Twitter */}
      <meta name="twitter:card" content={twitter_card ?? ""} />
      <meta name="twitter:site" content={twitter_site ?? ""} />
      <meta name="twitter:title" content={social_title ?? ""} />
      <meta name="twitter:description" content={social_description ?? ""} />
      <meta name="twitter:image" content={social_image.url ?? ""} />

      <meta name="robots" content="index,follow" />

      {/* Stripe */}
      {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script> */}
    </Head>
  );
};
