// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  hero: dynamic(() => import("./Hero")),
  pricing: dynamic(() => import("./Pricing")),
  text: dynamic(() => import("./Text")),
  text_card: dynamic(() => import("./TextCard")),
  text_image: dynamic(() => import("./TextImage")),
};
